.label-input {
  @apply relative;

  > a {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    @apply p-3 flex items-center justify-center text-gray-500;
    &:hover {
      @apply text-gray-800;
    }
  }

  label {
    @apply absolute top-0 left-0 z-20 border-l border-transparent pt-2 pl-3 text-xs text-gray-600 leading-none pointer-events-none;
    transition: opacity .1s;
  }

  input, select {
    @apply h-12 w-full px-3 pt-4 pb-0;
  }
  textarea {
    @apply w-full px-3 pt-5 pb-2;
  }

  input:focus + label,
  select:focus + label,
  textarea:focus + label {
    @apply text-blue-500;
  }

  input:placeholder-shown,
  textarea:placeholder-shown {
    @apply pt-0;
    + label {
      opacity: 0;
    }
  }

  textarea:placeholder-shown {
    @apply pt-3 pb-4;
  }
}
