.sidebar-card {
  @apply flex-1 flex flex-col bg-white border-2 rounded-lg relative overflow-hidden;

  &-content {
    @apply p-6 flex-1;
  }
}

@screen md {
  .sidebar-card {
    @apply border-0 overflow-auto;
    -webkit-overflow-scroll: touch;
    box-shadow: 0 0 20px 1px rgba(0,0,0,.1),
                0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05);
    &-teaser {
      @apply hidden;
    }
  }
}