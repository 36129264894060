.nl-label-input {
  @apply relative;

  > a {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    @apply p-3 flex items-center justify-center text-gray-800;
    &:hover {
      @apply text-gray-800;
    }
  }

  label {
    @apply absolute top-0 left-0 z-20 border-l border-transparent pt-2 pl-3 text-xs text-gray-800 leading-none pointer-events-none;
    transition: opacity .1s;
  }

  input, select {
    @apply h-12 w-full px-3 pt-4 pb-0;
  }
  textarea {
    @apply w-full px-3 pt-5 pb-2;
  }

  input:focus + label,
  select:focus + label,
  textarea:focus + label {
    @apply text-blue-500;
  }

  input:placeholder-shown,
  textarea:placeholder-shown {
    @apply pt-0;
    + label {
      opacity: 0;
    }
  }

  textarea:placeholder-shown {
    @apply pt-3 pb-4;
  }
}

.nl-input {
  @apply h-10 px-3 max-w-full inline-block appearance-none bg-white rounded-md outline-none placeholder-gray-800;
  font-size: 17px;

  &:focus {
    @apply border border-blue-500 bg-white relative z-10;
  }

  &.error {
    @apply border border-red-500;
  }

  &-lg {
    @apply h-12 text-lg;
  }

  select& {
    padding-right: 34px;
    background-image: url("data:image/svg+xml;utf8,<svg width='15px' height='9px' xmlns='http://www.w3.org/2000/svg'><polygon fill='currentColor' points='7.81967423 6.27619597 13.7275982 0.368272016 14.7710765 1.41175028 8.86315249 7.31967423 8.86827202 7.32479376 7.82479376 8.36827202 7.81967423 8.36315249 7.81455471 8.36827202 6.77107645 7.32479376 6.77619597 7.31967423 0.868272016 1.41175028 1.91175028 0.368272016'></polygon></svg>");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right 10px center;
  }

  textarea& {
    @apply h-auto p-2;
  }
}
