/* purgecss start ignore */
.glider-dot {
  @apply bg-gray-300;
  width: 8px;
  height: 8px;
  margin: 4px;
}
.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
  @apply bg-blue-900;
}
/* purgecss end ignore */