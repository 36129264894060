.slider {
  @apply bg-gray-300 rounded-full;
  appearance: none;
  width: 100%;
  height: 5px;
  outline: none;

  &::-webkit-slider-thumb {
    @apply w-6 h-6 bg-blue-500 rounded-full appearance-none cursor-pointer;
  }
}