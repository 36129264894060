@layer base {
  .title {
    &-1 {
      @apply font-bold leading-tight;
      font-size: 1.6rem;
    }

    &-2 {
      @apply text-2xl font-bold leading-tight;
    }

    &-3 {
      @apply text-xl font-bold;
    }

    &-4 {
      @apply text-lg font-semibold;
    }
  } 
}

@layer utilites {
  .title {
    &-1 {
      @apply font-bold leading-tight;
      font-size: 1.6rem;
    }

    &-2 {
      @apply text-2xl font-bold leading-tight;
    }

    &-3 {
      @apply text-xl font-bold;
    }

    &-4 {
      @apply text-lg font-semibold;
    }
  }
}

@screen md {
  .title {
    &-1 {
      @apply text-3xl;
    }
  }
}