.nl-radio-button-pill  {
  @apply rounded-xl items-center
}

.nl-radio-button {
  cursor: pointer;
  @apply min-h-8 inline-flex items-center;

  &-input {
    position: absolute;
    visibility: hidden;
  }

  &-control {
    @apply flex items-center justify-center w-5 h-5 mr-2 rounded-full bg-white border border-gray-400;
  }

  &:hover &-control {
    @apply border-primary-500;
  }

  &:hover.disabled &-control {
    @apply border-gray-400;
  }

  &-input:checked + &-control {
    @apply border-white bg-white;
    &::before {
      @apply bg-green-500 w-3 h-3 rounded-full;
      content: "";
    }
  }
}

