.checkbox {
  cursor: pointer;
  @apply min-h-8 inline-flex items-center;

  &-input {
    position: absolute;
    visibility: hidden;
  }

  &-control {
    @apply flex items-center justify-center w-5 h-5 mr-2 bg-white border border-gray-400 rounded;
  }

  &:hover &-control {
    @apply border-primary-500;
  }

  &-input:checked + &-control {
    @apply border-primary-500 bg-primary-500;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'><path fill='%23FFF' fill-rule='nonzero' d='M8.205 0L3.717 4.446 1.795 2.538 0 4.32 3.717 8 10 1.776'/></svg>");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 10px;
    }
  }
}
