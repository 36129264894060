/* purgecss start ignore */
.active-spinner {
  position: relative;

  &.up-active {
    color: transparent !important;
    &::before {
      content: "";
      @apply w-4 h-4 -mt-2 -ml-2 border-2 border-primary-500 rounded-full;
      position: absolute;
      top: 50%;
      left: 50%;
      border-top-color: transparent;
      animation: btn-spin 1s linear infinite;
    }
  }
}
/* purgecss end ignore */