/* purgecss start ignore */
.uppy-Dashboard-poweredBy {
  display: none !important;
}

.uppy-DashboardAddFiles {
  @apply border-2 border-dotted rounded-lg bg-gray-100 p-8 m-0 !important;
  background: url("../images/pdf.png") no-repeat 50% calc(50% - 40px);
  background-size: 44px 61px;

  &-info {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 0;
  }
}

[data-new-layout] [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  @apply border-none rounded-xl;
}

[data-new-layout] [data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  @apply text-2xl font-bold px-16 break-normal mt-20 text-center;
  text-transform: uppercase;
}

[data-new-layout] .uppy-Dashboard-browse {
  @apply text-2xl font-bold;
  text-transform: uppercase;
}

.uppy-Dashboard-dropFilesHereHint {
  @apply border-2 border-blue-500 rounded-lg;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.uppy-Dashboard-AddFiles-list {
  display: none;
}

/* new layout: */
.uppy-new-layout {
  .uppy-Dashboard-AddFiles-list {
    display: none;
  }
  .uppy-Dashboard-AddFiles {
    justify-content: end;
  }
}

.uppy-Dashboard-dropFilesTitle {
  @apply text-xl font-semibold text-blue-800 p-0 mt-8 max-w-full !important;
}

.uppy-Dashboard-browse {
  @apply text-blue-500 font-semibold underline;
}

.uppy-Dashboard-note {
  @apply text-sm text-gray-600 max-w-full w-full m-0 !important;
}

.uppy-Dashboard-inner {
  border: none;
  background-color: transparent;
  width: 100% !important;
  min-height: 0 !important;

  .uppy-Dashboard--modal & {
    @apply rounded-xl;
    padding: 10px;
    background: #fff;
    width: 640px !important;
  }
}

.uppy-DashboardContent-bar {
  @apply border-2 bg-gray-200 rounded-t-lg;
}

.uppy-DashboardContent-title {
  @apply text-base font-semibold;
}

.uppy-Dashboard-files {
  @apply border-2 border-t-0 border-b-0;
}

.uppy-StatusBar {
  @apply text-sm border-2 border-t-0 rounded-b-lg;
  &.is-waiting {
    @apply border-none;
  }
}
/* purgecss end ignore */
