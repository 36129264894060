/* purgecss start ignore */
.toastify {
  @apply py-3 px-4 leading-tight text-sm text-white bg-blue-500 shadow-lg rounded;
  position: fixed;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647;

  &.on {
    opacity: 1;
  }

  &-right {
    right: 15px;
  }
  
  &-left {
    left: 15px;
  }
  
  &-top {
    top: -150px;
  }
  
  &-bottom {
    bottom: -150px;
  }
}

.toast-close {
  opacity: 0.4;
  padding: 0 5px;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}
/* purgecss end ignore */