.segmented-control {
  @apply flex;

  label {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      visibility: hidden;
      & + span {
        @apply h-12 w-8 inline-flex justify-center items-center text-gray-500 font-semibold;
        &:hover {
          @apply text-blue-600;
        }
      }

      &:checked + span {
        @apply text-blue-600;
      }
    }
  }
}