.form {
  @apply rounded-lg bg-gray-100 border-2 p-4;

  up-modal & {
    @apply -m-4 mt-0 border-none rounded-none p-4;
  }
}

@screen md {
  .form {
    @apply p-6;

    up-modal & {
      @apply -m-6 mt-0 border-none rounded-none p-6;
    }
  }
}

form {
  &.up-active {
    pointer-events: none;
  }
}

.form {
  &.up-active {
    pointer-events: none;
  }

  &-error {
    @apply text-sm text-red-600;
  }

  &-group {
    @apply pb-4 mb-4 flex border-b;
    &:last-child {
      @apply pb-0 mb-0 border-none;
    }

    &-label {
      @apply flex-none h-10 flex items-center w-32 font-semibold;
    }
    &-input {
      @apply flex-1 max-w-full self-center;
    }
    &-help {
      @apply mt-2 leading-tight text-sm text-gray-600;
    }
  }
}

@screen md {
  .form {
    &-group {
      &-label {
        @apply w-1/4;
      }
    }
  }
}

.form-select-arrow {
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e);
}

.nl-checkbox:checked {
  border: none;
}
.nl-checkbox:checked + .nl-check-icon {
  display: flex;
}