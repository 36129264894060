.funmiles-card {
  @apply rounded-lg shadow-lg;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: calc(100%/1.55);
  background: #e53329;
  &-slogan {
    position: absolute;
    left: 30px;
    top: 30px;
  }
  &-number {
    position: absolute;
    top: 60%;
    left: 30px;
    color: #fdf0d9;
    text-shadow: #c7252a 0 2px;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
  }
  &-logo {
    position: absolute;
    bottom: -48%;
    right: -64%;
    width: 100%;
    height: 100%;
    padding: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 50%;
    background: #faf8f4;
  }
}