/* purgecss start ignore */
up-modal {
  z-index: 40;

  &-viewport {
    @apply p-4;
  }
  
  &-content {
    @apply flex flex-col p-4 rounded-xl overflow-hidden;
    width: calc(100vw - 2rem);
  }

  up-modal-content {
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 30%);
    z-index: 14000;
  }

  up-modal-box {
    background-color: transparent;
    padding: 0;
  }

  up-modal-dismiss {
    @apply text-[36px];
  } 

  &[up-flavor='drawer'] {
    up-modal-content {
      @apply p-0;
      width: auto;
      border-radius: 0;
    }
    up-modal-dialog {
      max-width: 100vw;
    }
    up-modal-box {
      max-width: 100vw;
      padding: 0;
      background-color: transparent;
    }
    up-modal-close {
      @apply h-20 w-16 flex items-center justify-center pb-2;
    }
    up-modal-dismiss {
      @apply h-20 w-16 flex items-center justify-center pb-2;
    }
  }
}

up-modal[size=medium] up-modal-box {
  width: auto;
}

@screen md {
  up-modal {
    &-content {
      @apply max-w-xl p-6;
    }
  }
}

/* up_modal */
/*
.up-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  overflow-x: hidden;

  &-backdrop {
    z-index: 11000;
    background-color: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-viewport {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 12000;
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: center;
    padding: 30px 10px;
    @apply flex justify-center;
  }

  &-dialog {
    max-width: 100%;
    margin: auto;
  }

  &.up-modal-animating {
    overflow-y: scroll;
    .up-modal-viewport {
      overflow-y: hidden;
    }
  }
}

.up-modal-dialog {
  z-index: 13000;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  display: inline-block;
  text-align: left;
}

.up-modal-content {
  z-index: 14000;
  overflow: hidden;
  @apply rounded-lg shadow-xl bg-white p-0 w-screen max-w-xl;
}

.up-modal-close {
  z-index: 15000;
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 34px;
  color: #666;
  cursor: pointer;
}

.up-modal[up-flavor='drawer'] {
  .up-modal-viewport {
    text-align: left;
    padding: 0;
  }
  &[up-position='right'] .up-modal-viewport {
    text-align: right;
  }
  .up-modal-dialog {
    max-width: 350px;
  }
  .up-modal-content {
    min-height: 100vh;
    box-sizing: border-box;
  }
}
*/
/* purgecss end ignore */