.toggle {
  position: relative;
  cursor: pointer;
  @apply inline-flex items-center leading-tight;

  input {
    position: absolute;
    visibility: hidden;
  }

  &-control {
    position: relative;
    @apply flex-none w-6 h-4 p-px rounded-full bg-red-600;
    transition: all .2s;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      @apply w-3 h-3 rounded-full bg-white;
      transition: all .1s;
    }
    input:checked + & {
      @apply bg-primary-500;
      &::after {
        transform: translateX(calc(100% - 4px));
      }
    }
  }

  &-border &-control {
    box-shadow: #fff 0 0 0 2px, rgba(0,0,0,.5) 0 2px 10px;
  }

  &-lg &-control {
    @apply h-6 w-10;
    &::after {
      @apply w-5 h-5;
    }
  }
}

.nl-toggle {
  position: relative;
  cursor: pointer;
  @apply inline-flex items-center leading-tight;

  input {
    position: absolute;
    visibility: hidden;
  }

  &-control {
    position: relative;
    @apply flex-none w-6 h-4 p-px rounded-full bg-gray-700;
    transition: all .2s;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      @apply w-3 h-3 rounded-full bg-white;
      transition: all .1s;
    }
    input:checked + & {
      @apply bg-primary-500;
      &::after {
        transform: translateX(calc(100% - 4px));
      }
    }
  }

  &-border &-control {
    
  }

  &-lg &-control {
    @apply h-6 w-10;
    &::after {
      @apply w-5 h-5;
    }
  } 
}