.checkbox-button {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    visibility: hidden;
    & + span {
      @apply h-8 px-3 inline-flex items-center bg-gray-200 rounded font-semibold text-sm;
      &:hover {
        @apply bg-gray-300;
      }
    }

    &:checked + span {
      @apply bg-primary-500 text-white;
    }
  }
}