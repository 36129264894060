.layout {
  @apply flex flex-col;
  
  &-main {
    @apply flex-1 w-full;
  }

  &-section {
    @apply mb-8;
    &:last-child {
      @apply mb-0;
    }
  }
}

@screen md {
  .layout {
    @apply flex-row items-start w-full;

    &-main {
      @apply flex-1;
    }

    &-sidebar {
      @apply sticky top-8 flex-none flex flex-col w-2/5 min-w-sm;
      max-height: calc(100vh - 4rem);
      &:first-child {
        @apply pr-8;
      }
      &:last-child {
        @apply pl-8;
      }
    }

    &-section {
      @apply mb-16;
      &:last-child {
        @apply mb-0;
      }
    }
  }
}
