@keyframes btn-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-up-active-spin {
  &.up-active {
    @apply relative;
    color: transparent !important;
    &::before {
      content: "SPIN";
      @apply w-4 h-4 -mt-2 -ml-2 border-2 border-white rounded-full;
      position: absolute;
      top: 50%;
      left: 50%;
      border-top-color: transparent;
      animation: btn-spin 1s linear infinite;
    }
  }
}

.btn {
  @apply relative inline-flex items-center justify-center h-10 p-0 px-4 overflow-hidden text-sm font-semibold leading-tight whitespace-nowrap bg-gray-200 rounded-full appearance-none cursor-pointer;

  &.up-active {
    color: transparent !important;
    &::before {
      content: "";
      @apply w-4 h-4 -mt-2 -ml-2 border-2 border-gray-700 rounded-full;
      position: absolute;
      top: 50%;
      left: 50%;
      border-top-color: transparent;
      animation: btn-spin 1s linear infinite;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover::after {
    background: rgba(255, 255, 255, .1);
  }

  &:active::after {
    background: rgba(0, 0, 0, .075);
  }

  &-sm {
    @apply h-8 px-3;
    font-size: .825rem;
  }

  &-lg {
    @apply h-12 px-8 text-base;
  }

  &-block {
    @apply flex;
  }

  &-primary {
    @apply text-white bg-primary-500;
    &.up-active {
      &::before {
        @apply border-white;
        border-top-color: transparent;
      }
    }
  }

  &-delete {
    @apply text-white bg-red-500;
    &.up-active {
      &::before {
        @apply border-white;
        border-top-color: transparent;
      }
    }
  }

  &-transparent {
    @apply text-gray-700 bg-transparent;
    &:hover {
      @apply text-gray-900 bg-gray-200;
    }
    &:active {
      @apply bg-gray-300;
    }
  }

  &-link {
    @apply px-1 bg-transparent text-primary-600;
    &:hover {
      @apply text-gray-800;
    }
  }
  
  &-submit {
    &.up-active,
    form.up-active & {
      color: transparent !important;
      &::before {
        content: "";
        @apply w-4 h-4 -mt-2 -ml-2 border-2 border-white rounded-full;
        position: absolute;
        top: 50%;
        left: 50%;
        border-top-color: transparent;
        animation: btn-spin 1s linear infinite;
      }
    }
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: .5;
  }
}