@import "tailwindcss/base";
@import "tailwindcss/components";
@import "../../shared/styles/components/*.css";
@import "./components/*.css";
@import "tailwindcss/utilities";
@import "./utilities/*.css";
@import "./tos.css";

[x-cloak] { display: none !important; }

