.progress-bar {
  @apply h-6 flex justify-between items-center overflow-hidden;
  position: relative;

  &::before {
    @apply bg-gray-300;
    content: "";
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    height: 4px;
    border-radius: 4px;
  }

  &-step {
    @apply relative w-4 h-4 rounded-full bg-gray-300 flex items-center justify-center text-white;
    z-index: 2;

    > * {
      display: none;
    }

    &.completed {
      background-color: var(--primary-500);
    }
    &.active {
      @apply w-6 h-6 relative;
      background-color: var(--primary-500);
      > * {
        display: block;
      }
      &::before {
        @apply w-64;
        content: "";
        pointer-events: none;
        position: absolute;
        z-index: 0;
        top: 50%;
        right: calc(100% - 4px);
        transform: translateY(-50%);
        height: 4px;
        border-radius: 4px;
        background-color: var(--primary-500);
      }
    }
  }
}